export default {
  namespace: "VUE_ADMIN_CMS",

  // 初始化命名域
  init(namespace = "") {
    this.namespace = namespace || this.namespace;
  },

  // 设置数据
  set(key, value) {
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    localStorage.setItem(`${this.namespace}_${key}`, value);
  },

  // 获取数据
  get(key) {
    let value = localStorage.getItem(`${this.namespace}_${key}`);
    try {
      return JSON.parse(value); // 尝试将JSON字符串解析为对象
    } catch (e) {
      return value; // 如果不是JSON字符串，则返回原始值
    }
  },

  // 移除数据
  remove(key) {
    localStorage.removeItem(`${this.namespace}_${key}`);
  },

  // 清空数据
  clear() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(this.namespace)) {
        localStorage.removeItem(key);
      }
    });
  },
};
