import Vue from "vue";
import VueRouter from "vue-router";
import localStorageUtils from "@/utils/localStorageUtils";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: () => import("@/components/LayoutView.vue"),
      children: [
        // {
        //   path: "/",
        //   name: "控制台",
        //   icon: "el-icon-s-cooperation",
        //   component: () => import("@/views/HomeView.vue"),
        // },
  
        {
          path: "/message",
          name: "报修管理",
          icon: "el-icon-s-tools",
          component: () => import("@/components/MainView"),
          children: [
            {
              path: "messageList",
              name: "申请报修列表",
              component: () => import("@/views/controlPanel/StudentList.vue"),
            },
            // {
            //   path: "homework",
            //   name: "作业列表",
            //   component: () => import("@/views/Students/HomeWork.vue"),
            // },
          ],
        },

        {
                path: "/feedback",
                name: "客户反馈",
                icon: "el-icon-message",
              component: () => import("@/components/MainView"),
                children: [
                  {
                    path: "feedbackList",
                    name: "意见建议",
                    component: () => import("@/views/controlPanel/feedbackList.vue"),
                  },
                {
                    path: "complaintList",
                    name: "投诉",
                    component: () => import("@/views/controlPanel/complaintList.vue"),
                  },
                ],
              },
        {
          path: "/info",
          name: "新闻管理",
          icon: "el-icon-edit",
        component: () => import("@/components/MainView"),
          children: [
            {
              path: "infomanage",
              name: "新闻录入",
              component: () => import("@/views/controlPanel/InfoManage.vue"),
            },
            {
              path: "infoManageList",
              name: "已发布新闻列表",
              component: () => import("@/views/controlPanel/infoManageList.vue"),
            },

          ],
        },
          {
                path: "/equipmentBrandEntity",
                name: "品牌管理",
                icon: "el-icon-s-cooperation",
              component: () => import("@/components/MainView"),
                children: [
                  {
                    path: "equipmentBrandEntity",
                    name: "品牌列表",
                    component: () => import("@/views/controlPanel/equipmentBrandEntity.vue"),
                  },
                ],
              },
          {
                  path: "/personnel",
                  name: "人员管理",
                  icon: "el-icon-user",
                 component: () => import("@/components/MainView"),
                  children: [
                    {
                      path: "personnelManagement",
                      name: "维修人员管理",
                      component: () => import("@/views/controlPanel/personnelManagement.vue"),
                    },
                    {
                          path: "servePersonnelManage",
                          name: "人员管理",
                          component: () => import("@/views/controlPanel/servePersonnelManage.vue"),
                          meta: { invisible: false }

                        },
                      ],
                },
        // {
        //   path: "/graphs",
        //   name: "数据分析",
        //   icon: "el-icon-s-marketing",
        //   component: () => import("@/components/MainView"),
        //   children: [
        //     {
        //       path: "overview",
        //       name: "数据概览",
        //       component: () => import("@/views/Graphs/OverView.vue"),
        //     },
        //     {
        //       path: "mapview",
        //       name: "地图概览",
        //       component: () => import("@/views/Graphs/MapView.vue"),
        //     },
        //     {
        //       path: "travelmap",
        //       name: "旅游地图",
        //       component: () => import("@/views/Graphs/TravelMap.vue"),
        //     },
        //     {
        //       path: "scoremap",
        //       name: "分数地图",
        //       component: () => import("@/views/Graphs/ScoreMap.vue"),
        //     },
        //   ],
        // },
        // {
        //   path: "/users",
        //   name: "用户中心",
        //   icon: "el-icon-s-custom",
        //   component: () => import("@/views/Users"),
        // },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
          path: "/noAccess",
          name: "noAccess",
          component: () => import("@/views/noAccess.vue"),
      },
    {
      path: "*",
      name: "notfound",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = localStorageUtils.get("token");
  if (token) {
    // 如果有token，并且试图访问登录页，则重定向到首页
    if (to.path === "/login") return next("/");
  const userPermissions =window.localStorage.getItem('townId');
console.log(userPermissions,"---userPermissions")
  // 假设根据用户权限等条件来判断是否要隐藏服务人员管理菜单项，这里简单示例直接设置为隐藏

  if(userPermissions != null){
  const shouldHideServePersonnelManage = true;
    if (shouldHideServePersonnelManage) {
      const servePersonnelManageRoute = router.options.routes.find(route => {
        return route.name === '人员管理' && route.children.find(child => child.name === '人员管理');
      });
      if (servePersonnelManageRoute && servePersonnelManageRoute.children) {
        const targetChildRoute = servePersonnelManageRoute.children.find(child => child.name === '人员管理');
        if (targetChildRoute) {
          targetChildRoute.meta.invisible = true;
        }
      }
    }
  }

    if (to.meta && to.meta.invisible) {
        // 如果路由是不显示的，则重定向到首页或其他页面
        next('/noAccess'); // 或者其他你想要重定向的路径
      } 
    // 其他情况下直接放行
    return next();

  }

  // 如果没有token，并且试图访问非登录页，则重定向到登录页
  if (to.path !== "/login") return next("/login");
  // 其他情况下直接放行
  return next();
});

export default router;
