import Vue from "vue";
import Vuex from "vuex";
// import { getHomeDataApi } from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    homeData: {},
  },
  mutations: {
    INIT_HOME_DATA(state, data) {
      state.homeData = data;
    },
  },
  actions: {
    // getHomeData({ commit }) {
    //   getHomeDataApi().then((res) => {
    //     // console.log(res);
    //     commit("INIT_HOME_DATA", res.data);
    //   });
    // },
  },
});
